<template>
    <main>
        <section class="content">
            <DsToastContainer />

            <AffiliatePortal />
        </section>
    </main>
</template>
<script>
import { DsToastContainer } from '@infusionsoft/design-system';
import AffiliatePortal from '@/pages/AffiliatePortal.vue';

export default {
    components: {
        DsToastContainer,
        AffiliatePortal,
    },
};
</script>

<style lang="scss" scoped>
.content {
    height: 100vh;
}
</style>
